export const environment = {
  production: false,
  APP_NAME: 'TM Travel Content Approval',
  COMPANY_NAME: 'TMA Technology Group',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  TRAVEL_ASSIST_APP_URL: 'https://tmtrav-travel-dev.tma-swerp.com',
  CAR_RENTAL_APP_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com',
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
  ECOMMERCE_APP_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  HOTEL_APP_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  APPROVAL_APP_URL: 'https://approval-tmtrav-dev.tma-swerp.com/',
  API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  APP_DEBUG_URL: 'https://localhost:4200',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status_admin',
  USER_PROFILE: 'user_profile_admin',
  TOKEN_KEY: 'access_token_admin',
  TOKEN: 'token_admin',
  API_TIMEOUT: 60000,
  languages: ['en', 'vi'],
  SECRET_KEY: 'TMA1997254000301002839978000/soctrip@tma.com.vn',
  CUSTOMIZE_TYPE: [
    'COMMENT',
    'POST',
    'LIVESTREAM',
    'STORY',
    'ADS',
    'POST_SUGGESTION',
    'ADVERTISEMENT'
  ],
  // Approval config for each module
  TOUR_APPROVE_LEGALITY_ENABLED: true,
  CAR_APPROVE_LEGALITY_ENABLED: true
};
